import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Scandipwa/Component/TypographyHeader/Component */
export class TypographyHeaderComponent extends PureComponent {
    static propTypes = {
        heading: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
        children: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['black', 'white', 'blue']),
        className: PropTypes.string
    };

    static defaultProps = {
        color: 'black',
        className: ''
    };

    render() {
        const {
            heading, children, color, className
        } = this.props;
        const HeadingTag = heading;

        return (
            <HeadingTag
              block="TypographyHeader"
              className={ className }
              mods={ { heading, color } }
            >
                { children }
            </HeadingTag>
        );
    }
}

export default TypographyHeaderComponent;
