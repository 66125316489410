export const OPINION_SLIDER_NAME = 'product-opinion-slider';
export const OPINION_SLIDER_ARTICLE = 'articles-opinion-slider';

/** @namespace Scandipwa/Component/SliderWidget/Config/getSliderWidgetSettings */
export const getSliderWidgetSettings = (RenderNextArrow, RenderPrevArrow) => ({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 728,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
