import AdvoxSlider from 'Component/AdvoxSlider';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Player from 'Component/Player';
import Slider from 'Component/Slider';
import {
    getSliderWidgetSettings,
    OPINION_SLIDER_ARTICLE,
    OPINION_SLIDER_NAME
} from 'Component/SliderWidget/SliderWidget.config';
import TypographyHeader from 'Component/TypographyHeader';
import {
    SliderWidget as SourceSliderWidget
} from 'SourceComponent/SliderWidget/SliderWidget.component';

import './SliderWidget.override.style';

/** @namespace Scandipwa/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidget {
    renderSlide = (slide, i) => {
        const {
            slide_text,
            isPlaceholder,
            title: block,
            embed_code
        } = slide;

        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ i }
            >
                { embed_code ? (
                    <div
                      block="SliderWidget"
                      elem="FigureVideo"
                    >
                        <Player
                          className="SliderWidget-Video"
                          url={ embed_code }
                          preview={ this.getSlideImage(slide) }
                          muted
                        />
                    </div>
                ) : (
                    <Image
                      mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                      ratio="custom"
                      src={ this.getSlideImage(slide) }
                      isPlaceholder={ isPlaceholder }
                    />
                ) }

                <figcaption
                  block="SliderWidget"
                  elem="Figcaption"
                  mix={ { block } }
                >
                    <Html content={ slide_text || '' } />
                </figcaption>
            </figure>
        );
    };

    renderSlideOpinion = (slide, i) => {
        const {
            slide_text,
            title: block
        } = slide;

        return (
            <figure
              block="SliderWidget"
              elem="Opinion"
              key={ i }
            >
                <div block="SliderWidget" elem="OpinionWrapper">
                    <div block="SliderWidget" elem="OpinionImage">
                        <img src={ this.getSlideImage(slide) } alt={ block } />
                    </div>
                    <div
                      block="SliderWidget"
                      elem="OpinionContent"
                      mix={ { block } }
                    >
                        <TypographyHeader heading="h3">{ block }</TypographyHeader>
                        <Html content={ slide_text || '' } />
                    </div>
                </div>
            </figure>
        );
    };

    renderOpinionSlider() {
        const { activeImage } = this.state;
        const { slider: { slides, title: block } } = this.props;

        return (
            <div block="SliderWidget" mods={ { isOpinion: true } }>
                <Slider
                  mix={ { block: 'SliderWidget', mix: { block } } }
                  showArrows
                  activeImage={ activeImage }
                  onActiveImageChange={ this.onActiveImageChange }
                >
                    { slides.map(this.renderSlideOpinion) }
                </Slider>
            </div>
        );
    }

    renderArticleOpinionSlide = (slide, i) => {
        const {
            slide_link: url,
            title
        } = slide;

        if (!url) {
            return (
                <div block="ArticlesOpinion" elem="Item" key={ i }>
                    <div block="Image" elem="Wrapper">
                        <img
                          src={ this.getSlideImage(slide) }
                          alt={ title }
                        />
                    </div>
                </div>
            );
        }

        return (
            <div block="ArticlesOpinion" elem="Item" key={ i }>
                <div block="Image" elem="Wrapper">
                    <Link to={ url } target="_blank">
                        <img
                          src={ this.getSlideImage(slide) }
                          alt={ title }
                        />
                    </Link>
                </div>
            </div>
        );
    };

    renderPlaceholder() {
        return <div block="SliderWidget" elem="Placeholder" />;
    }

    renderNextArrow(props) {
        const { className, style, onClick } = props;

        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                <ChevronIcon direction={ RIGHT } />
            </div>
        );
    }

    renderPrevArrow(props) {
        const { className, style, onClick } = props;

        return (
            <div
              className={ className }
              style={ style }
              onClick={ onClick }
            >
                <ChevronIcon direction={ LEFT } />
            </div>
        );
    }

    render() {
        const {
            slider: {
                slider_id,
                slides,
                title: block,
                is_active,
                slides_to_display: slidesToDisplay,
                slides_to_display_tablet: slidesToDisplayTablet,
                slides_to_display_mobile: slidesToDisplayMobile,
                slides_to_scroll: slidesToScroll,
                slides_to_scroll_tablet: slidesToScrollTablet,
                slides_to_scroll_mobile: slidesToScrollMobile
            }
        } = this.props;
        const isOpinionSlider = OPINION_SLIDER_NAME === block;
        const isArticleOpinionSlider = OPINION_SLIDER_ARTICLE === block;

        if (!slider_id) {
            return this.renderPlaceholder();
        }

        if (!is_active) {
            return null;
        }

        if (isOpinionSlider) {
            return this.renderOpinionSlider();
        }

        if (isArticleOpinionSlider) {
            const settingsDesktop = {
                ...(slidesToDisplay && { slidesToShow: slidesToDisplay }),
                ...(slidesToScroll && { slidesToScroll })
            };

            const settingsTablet = {
                ...(slidesToDisplayTablet && { slidesToShow: slidesToDisplayTablet }),
                ...(slidesToScrollTablet && { slidesToScroll: slidesToScrollTablet })
            };

            const settingsMobile = {
                ...(slidesToDisplayMobile && { slidesToShow: slidesToDisplayMobile }),
                ...(slidesToScrollMobile && { slidesToScroll: slidesToScrollMobile })
            };

            const settings = {
                ...settingsDesktop,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            ...settingsTablet
                        }
                    },
                    {
                        breakpoint: 728,
                        settings: {
                            ...settingsMobile
                        }
                    }
                ],
                arrows: false
            };

            return (
                <AdvoxSlider settings={ settings }>
                    { slides.map(this.renderArticleOpinionSlide) }
                </AdvoxSlider>
            );
        }

        return (
            <AdvoxSlider
              mix={ { block: 'SliderWidget', mix: { block } } }
              variant="none"
              settings={ getSliderWidgetSettings(this.renderNextArrow.bind(this), this.renderPrevArrow.bind(this)) }
            >
                { slides.map(this.renderSlide) }
            </AdvoxSlider>
        );
    }
}

export default SliderWidgetComponent;
