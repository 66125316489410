import React, { PureComponent } from 'react';
import { InView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import Icons from 'Component/Icons';

import './Player.style.scss';

/** @namespace Scandipwa/Component/Player/Component */
export class Player extends PureComponent {
    __construct(props) {
        super.__construct(props);

        const {
            muted,
            preview
        } = props;

        this.state = {
            playing: false,
            muted: muted || false,
            previewClicked: !preview || false
        };
    }

    onChangePlaying(value) {
        this.setState((prev) => ({
            playing: value ?? !prev.playing
        }));
    }

    onChangeMute(value) {
        this.setState((prev) => ({
            muted: value ?? !prev.muted
        }));
    }

    onClickPreview() {
        this.setState({
            previewClicked: true,
            playing: true
        });
    }

    render() {
        const { url, preview, className } = this.props;
        const {
            playing,
            muted,
            previewClicked
        } = this.state;

        if (!url) {
            return null;
        }

        return (
            <InView
              delay={ 100 }
              rootMargin="0px -50px 0px -50px"
              onChange={ (inView) => previewClicked && playing && this.onChangePlaying(inView) }
            >
                { ({ ref }) => (
                    <div block="Player" ref={ ref }>
                        <ReactPlayer
                          onClickPreview={ this.onClickPreview.bind(this) }
                          className={ `${className}` }
                          url={ url }
                          width="100%"
                          height="100%"
                          playing={ playing }
                          muted={ muted }
                          light={ preview || false }
                          playsinline
                          loop
                        />
                        <div block="Player" elem="Overlay" />
                        { previewClicked ? (
                            <div block="Player" elem="Controls">
                                <button block="Player" elem="Control" onClick={ () => this.onChangePlaying() }>
                                    { playing ? <Icons name="pause" height="24px" width="24px" />
                                        : <Icons name="play" height="24px" width="24px" /> }
                                </button>
                                <button block="Player" elem="Control" onClick={ () => this.onChangeMute() }>
                                    { muted ? <Icons name="muted" height="24px" width="24px" />
                                        : <Icons name="unmuted" height="24px" width="24px" /> }
                                </button>
                            </div>
                        ) : null }
                    </div>
                ) }
            </InView>
        );
    }
}

export default Player;
