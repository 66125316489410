import {
    SliderQuery as SourceSliderQuery
} from 'SourceQuery/Slider.query';

/** @namespace Scandipwa/Query/Slider/Query */
export class SliderQuery extends SourceSliderQuery {
    _getSliderFields() {
        return [
            ...super._getSliderFields(),
            'is_active',
            'slides_to_display',
            'slides_to_display_tablet',
            'slides_to_display_mobile',
            'slides_to_scroll',
            'slides_to_scroll_tablet',
            'slides_to_scroll_mobile'
        ];
    }

    _getSlideFields() {
        return [
            ...super._getSlideFields(),
            'slide_text',
            'slide_id',
            'mobile_image',
            'desktop_image',
            'title',
            'is_active',
            'slide_link',
            'embed_code'
        ];
    }
}

export default new SliderQuery();
